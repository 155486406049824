import React from "react";

export default function Footer() {
  return (
    <div className="footer">
      <div className="auto__container">
        <div className="footer__inner">
          <div className="footer__inner-logo">
            <img
              src={process.env.PUBLIC_URL + "/images/lightLogo.svg"}
              alt="lightLogo"
            />
          </div>
          <div className="footer__inner-links">
            <a href="info@soracaya.com">info@soracaya.com</a>
            <a href="tel:720.81.222">720.81.222</a>
          </div>
        </div>
      </div>
    </div>
  );
}
