import React from "react";
import SignIn from "./SignIn";
import { Navigate, Route, Routes } from "react-router-dom";

export default function Auth({ type, setType, setIsLogged }) {
  return (
    <>
      <div className="auth">
        <div className="authIntro">
          <div className="authIntro__bg">
            <img
              src={process.env.PUBLIC_URL + "/images/intro.jpg"}
              alt="intro"
            />
          </div>
        </div>
        <Routes>
          <Route
            path="sign-in"
            element={
              <SignIn type={type} setType={setType} setIsLogged={setIsLogged} />
            }
          />
        </Routes>
      </div>
    </>
  );
}
