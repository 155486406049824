import React from "react";

export default function Landing() {
  return (
    <section className="intro">
      <div className="auto__container">
        <div className="intro__inner">
          <div className="intro__inner-box">
            <h1>
              We are dedicated to seeking out and investing in the most
              promising metals, minerals, and energy projects around the world.
            </h1>
          </div>
        </div>
      </div>
    </section>
  );
}
