import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Auth from "./Auth/Auth";
import Header from "./Base/Header";
import Footer from "./Base/Footer";
import Landing from "./Pages/Landing";

export default function App() {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <>
      <Routes>
        <Route path="auth/*" element={<Auth />} />
        <Route path="" element={<AppInner />} />
      </Routes>
    </>
  );
}
const AppInner = () => {
  return (
    <>
      <Header />
      <main className="wrapper">
        <Routes>
          <Route path="" element={<Landing />} />
        </Routes>
      </main>
      <Footer />
    </>
  );
};
