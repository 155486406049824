import React from "react";
import { Link } from "react-router-dom";

export default function Header() {
  return (
    <div className="header">
      <div className="auto__container">
        <div className="header__inner">
          <Link to="/" className="header__inner-logo">
            <img
              src={process.env.PUBLIC_URL + "/images/darkLogo.svg"}
              alt="darkLogo"
            />
          </Link>
          <Link to="auth/sign-in" className="header__inner-link">
            Login
          </Link>
        </div>
      </div>
    </div>
  );
}
