import React, { useState } from "react";
import { closeEye, eye } from "../Base/SVG";
import { Link } from "react-router-dom";

export default function SignIn() {
  const [error, setError] = useState(false);
  const [isShow, setIsShow] = useState(null);
  const [form, setForm] = useState({
    email: "",
    password: "",
    remember: false,
  });
  const onChangeInput = (input) => (e) => {
    setForm((form) => ({ ...form, [input]: e.target.value }));
  };
  const updateForm = (data) => {
    setForm((form) => ({ ...form, ...data }));
  };
  const btnClick = () => {
    if (form.email === "" || form.password === "") setError(true);
    else {
      setError(false);
    }
  };
  return (
    <div className="authForm">
      <div className="authForm__inner">
        <Link to="/" className="authForm__logo">
          <img
            src={process.env.PUBLIC_URL + "/images/darkLogo.svg"}
            alt="logo"
          />
        </Link>
        <legend>Login</legend>
        <div className="input__outer">
          <label htmlFor="">Email</label>

          <div className={"input  " + (form.email === "" && error && "error")}>
            <input
              type="email"
              value={form.email}
              onChange={onChangeInput("email")}
            />
            {form.email === "" && error && (
              <span className="errorText">Please enter your email</span>
            )}
          </div>
        </div>
        <div className="input__outer">
          <label htmlFor="">Password</label>
          <div className="input pass">
            <input
              type={isShow === "password" ? "text" : "password"}
              value={form.password}
              onChange={onChangeInput("password")}
            />
            {form.password === "" && error && (
              <span className="errorText">Please enter your email</span>
            )}
            <button
              type="button"
              onClick={() => {
                if (isShow === "password") setIsShow(null);
                else setIsShow("password");
              }}
            >
              {isShow === "password" ? eye : closeEye}
            </button>
          </div>
        </div>
        <div className="authForm__row">
          <div className="check">
            <input
              type="checkbox"
              checked={form.remember}
              onChange={() => updateForm({ remember: !form.remember })}
            />
            <label htmlFor="">Remember me</label>
          </div>
          <div className="forget">
            <Link to="">Forgot password</Link>
          </div>
        </div>

        <div className="authForm__btn">
          <button type="button" className="button main" onClick={btnClick}>
            Log in
          </button>
        </div>
      </div>
    </div>
  );
}
